import { useEffect } from 'react'
import { useNavigate } from 'react-router'

import { AdminRole } from '@/out/admin'
import { useAuthSelector } from '@/store'

export default function useEnforceRole(role: AdminRole, path: string) {
  const { role: userRole } = useAuthSelector()
  const navigate = useNavigate()

  useEffect(() => {
    if (userRole === role) return
    if (role === 'admin' && userRole === 'owner') return

    navigate(path)
  }, [userRole, role, navigate, path])
}
