// import MobileNav from './mobile-nav'
import clsx from 'clsx'
import { TowerControl } from 'lucide-react'
import { FC } from 'react'
import { Link, useLocation, useNavigate } from 'react-router'
import r from 'routes'

import { Button } from '@/components/ui/button'

type Props = {
  to: string
  isActive: boolean
  children: React.ReactNode
}

const NavLink: FC<Props> = ({ to, children, isActive }) => (
  <Link
    to={to}
    className={clsx(
      'whitespace-nowrap text-sm transition-colors px-3 py-1.5 rounded-md',
      isActive
        ? 'text-foreground bg-muted font-medium'
        : 'text-muted-foreground hover:text-foreground hover:bg-muted/50'
    )}
  >
    {children}
  </Link>
)

const WatchTowerHeader: FC = () => {
  const navigate = useNavigate()
  const currentPath = useLocation().pathname

  return (
    <header className="sticky top-0 z-50 flex items-center justify-between gap-3 p-3 border-b bg-background border-border ">
      <div className="flex items-center mr-4 space-x-2 md:mr-20">
        <TowerControl className="w-6 h-6" />
        <span className="hidden text-lg md:inline">Watch Tower</span>
      </div>
      <nav className="flex-1 flex gap-1.5">
        <NavLink to={r.watchTower.root} isActive={r.watchTower.root === currentPath}>
          Dashboard
        </NavLink>
        <NavLink to={r.watchTower.accounts.root} isActive={currentPath.startsWith(r.watchTower.accounts.root)}>
          Accounts
        </NavLink>
      </nav>
      <div className="flex items-center gap-1.5">
        <Button size="sm" variant="outline" onClick={() => navigate('/')}>
          <span className="md:hidden">Back</span>
          <span className="hidden md:inline">Back to my account</span>
        </Button>
      </div>
    </header>
  )
}

export default WatchTowerHeader
