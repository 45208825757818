const r = {
  root: '/',
  legal: {
    dataProcessingAddendum: '/legal/data-processing-addendum',
  },
  authentication: {
    signUp: '/sign-up',
  },
  sso: {
    callback: '/sso/callback',
  },
  settings: {
    root: '/settings',
    branding: '/settings/branding',
    adminManagement: '/settings/admin-management',
    smsConfiguration: '/settings/sms-configuration',
    customerLookup: '/settings/customer-lookup',
    singleSignOn: '/settings/single-sign-on',
    billing: '/settings/billing',
    logging: '/settings/logging',
    auditLog: '/settings/audit-log',
    developers: '/settings/developers',
  },
  support: {
    root: '/support',
    ticket: '/support/ticket',
  },
  sales: {
    root: '/sales',
  },
  resources: {
    root: '/resources',
  },
  apps: {
    'one-time-passcode': {
      root: '/one-time-passcode',
      authentications: '/one-time-passcode/authentications',
      pricing: '/one-time-passcode/pricing',
      settings: {
        root: '/one-time-passcode/settings',
        general: '/one-time-passcode/settings/general',
        smsContent: '/one-time-passcode/settings/sms-content',
        webhooks: '/one-time-passcode/settings/webhooks',
        billing: '/one-time-passcode/settings/billing',
      },
    },
    'document-signature': {
      root: '/document-signature',
      signatures: '/document-signature/signatures',
      documents: {
        root: '/document-signature/documents',
        new: '/document-signature/documents/new',
        edit: (id: string = ':id', version: string = ':version') => `/document-signature/documents/${id}/${version}`,
        history: (id: string = ':id') => `/document-signature/documents/${id}/history`,
      },
      pricing: '/document-signature/pricing',
      settings: {
        root: '/document-signature/settings',
        general: '/document-signature/settings/general',
        storage: '/document-signature/settings/storage',
        smsContent: '/document-signature/settings/sms-content',
        webhooks: '/document-signature/settings/webhooks',
        billing: '/document-signature/settings/billing',
      },
    },
    'e-form': {
      root: '/e-form',
      submissions: '/e-form/submissions',
      forms: {
        root: '/e-form/forms',
        new: '/e-form/forms/new',
        edit: (id: string = ':id') => `/e-form/forms/${id}`,
      },
      pricing: '/e-form/pricing',
      settings: {
        root: '/e-form/settings',
        general: '/e-form/settings/general',
        smsContent: '/e-form/settings/sms-content',
        webhooks: '/e-form/settings/webhooks',
        billing: '/e-form/settings/billing',
      },
    },
    'file-upload': {
      root: '/file-upload',
      uploads: '/file-upload/uploads',
      pricing: '/file-upload/pricing',
      settings: {
        root: '/file-upload/settings',
        general: '/file-upload/settings/general',
        fileStorage: '/file-upload/settings/file-storage',
        smsContent: '/file-upload/settings/sms-content',
        webhooks: '/file-upload/settings/webhooks',
        billing: '/file-upload/settings/billing',
      },
    },
    passkeys: {
      root: '/passkeys',
      activity: '/passkeys/activity',
      enrollments: '/passkeys/enrollments',
      pricing: '/passkeys/pricing',
      settings: {
        root: '/passkeys/settings',
        general: '/passkeys/settings/general',
        smsContent: '/passkeys/settings/sms-content',
        webhooks: '/passkeys/settings/webhooks',
        billing: '/passkeys/settings/billing',
      },
    },
    payments: {
      root: '/payments',
      payments: '/payments/payments',
      pricing: '/payments/pricing',
      products: '/payments/products',
      settings: {
        root: '/payments/settings',
        general: '/payments/settings/general',
        stripe: '/payments/settings/stripe',
        stripeCallback: '/payments/settings/stripe/callback',
        smsContent: '/payments/settings/sms-content',
        webhooks: '/payments/settings/webhooks',
        billing: '/payments/settings/billing',
      },
    },
  },
  watchTower: {
    root: '/watch-tower',
    accounts: {
      root: '/watch-tower/accounts',
      show: (id: string = ':id') => `/watch-tower/accounts/${id}`,
    },
  },
}

export default r
