import { ReactNode, useState } from 'react'

import { Badge } from '@/components/ui/badge'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import { ActionTimer } from '@/hooks/useActionTimer'
import { Logging, LoggingDestination } from '@/out/logging'
import { StorageDestination } from '@/out/storage'
import fadeIn from '@/util/fadeIn'
import { cn } from '@/lib/utils'

type Purpose = 'logging' | 'storage' | 'sms' | 'sso'

type SettingsCardProps<P extends Purpose> = {
  title: string
  description: string
  icon: React.ReactNode
  configuration: P extends 'logging' ? Logging[LoggingDestination] : Storage[StorageDestination]
  onRemove?: () => void
  removeTimer?: ActionTimer
  children: ReactNode
  disabled?: boolean
}

function SettingsCard<P extends Purpose>({
  title,
  description,
  icon,
  configuration,
  onRemove,
  children,
  disabled,
}: SettingsCardProps<P>) {
  const [isEnabled, setIsEnabled] = useState(!!configuration)

  return (
    <Card className={cn('flex flex-col max-w-lg h-fit', disabled && 'opacity-75')}>
      <CardHeader className="space-y-3">
        <CardTitle className="flex justify-between items-center">
          <div className="flex items-center gap-3">
            <div className="text-primary">{icon}</div>
            <span className="font-semibold text-foreground/90">{title}</span>
          </div>
          {configuration && (
            <Badge variant="outline" className="px-3 py-1 transition-colors">
              <div className="bg-emerald-500 mr-2 rounded-full ring-2 ring-emerald-500/20 size-2 animate-pulse"></div>
              <span className="font-medium text-emerald-600">Connected</span>
            </Badge>
          )}
        </CardTitle>
        <CardDescription className="text-muted-foreground/80 leading-relaxed">{description}</CardDescription>
      </CardHeader>
      {!disabled && (
        <CardContent className={`mb-auto ${isEnabled || configuration ? 'space-y-6' : ''}`}>
          {!configuration && (
            <div className="flex justify-between items-center bg-muted/40 p-3 rounded-lg">
              <Label htmlFor={`enable-${title.toLowerCase()}`} className="font-medium cursor-pointer">
                Enable {title}
              </Label>
              <Switch
                id={`enable-${title.toLowerCase()}`}
                checked={isEnabled}
                disabled={!!configuration}
                onCheckedChange={checked => {
                  setIsEnabled(checked)
                  if (!checked && configuration) {
                    onRemove?.()
                  }
                }}
                className="data-[state=checked]:bg-primary"
              />
            </div>
          )}
          <div className={fadeIn(isEnabled)}>{children}</div>
        </CardContent>
      )}
    </Card>
  )
}

export default SettingsCard
