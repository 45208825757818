import { getSvgPath } from 'figma-squircle'
import { LucideIcon } from 'lucide-react'
import { FC } from 'react'

import { cn } from '@/lib/utils'

interface AppIconProps {
  Icon: LucideIcon | FC<React.SVGProps<SVGSVGElement>>
  color: string
  className?: string
}

const AppIcon: FC<AppIconProps> = ({ Icon, color, className = '' }) => {
  const squirclePath = getSvgPath({
    width: 100,
    height: 100,
    cornerRadius: 25,
    cornerSmoothing: 0.8,
  })

  return (
    <div
      className={cn(
        `relative flex justify-center items-center w-16 h-16 group-hover:scale-105 transition-transform duration-300 transform`,
        color === 'gray' && 'outline-1 outline-dashed outline-gray-300 rounded-2xl p-1',
        className
      )}
    >
      <svg width="64" height="64" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" className="absolute">
        <path
          d={squirclePath}
          className={`fill-${color}-50 group-hover:fill-${color}-100 transition-colors duration-300`}
        />
      </svg>
      <div className="relative z-10 flex items-center justify-center w-10 h-10">
        <Icon className={`w-8 h-8 text-${color}-500 group-hover:text-${color}-600 transition-colors duration-300`} />
      </div>
    </div>
  )
}

export default AppIcon
