import { FC, ReactNode, useCallback } from 'react'
import { NonUndefined } from 'react-hook-form'

import SettingsLayout from '@/components/layout/settings-layout'
import Settings from '@/components/settings'
import { ActionTimer, idleTimer } from '@/hooks/useActionTimer'
import useObject from '@/hooks/useObject'
import { SSO, SSOProvider } from '@/out/single-sign-on'
import { retrieveSSO } from '@/thunks/single-sign-on/retrieve'
import { SSOCredentials } from '@/thunks/single-sign-on/upsert'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

import AzureAD from './azure'
import OIDC from './oidc'
import Okta from './okta'
import useSSORemove from './useSSORemove'
import useSSOUpsert from './useSSOUpsert'

export type SSOProps<T extends SSOProvider> = {
  setupTimer: ActionTimer
  onSetup: (provider: T, config: NonUndefined<SSOCredentials[T]>) => Promise<SSO>
  removeTimer: ActionTimer
  onRemove: (provider: SSOProvider) => void
}

export type FormProps<T extends SSOProvider> = {
  timer: ActionTimer
  onSetup: (config: NonUndefined<SSOCredentials[T]>) => void
}

const SingleSignOn: FC = () => {
  const [configuration, { patch, wasLoaded }] = useObject(retrieveSSO, {})

  const [upsert, { timer: setupTimer }] = useSSOUpsert((provider, config) => patch({ [provider]: config }))
  const [remove, { timer: removeTimer }] = useSSORemove(provider => patch({ [provider]: undefined }))

  const props = useCallback(
    <T extends SSOProvider>(provider: T): SSOProps<T> => ({
      onSetup: upsert,
      setupTimer: setupTimer.id === provider ? setupTimer : idleTimer,
      onRemove: remove,
      removeTimer: removeTimer.id === provider ? removeTimer : idleTimer,
    }),
    [upsert, remove, setupTimer, removeTimer]
  )

  // Find active providers
  const activeProvider =
    configuration &&
    Object.entries(configuration).find(
      ([_, config]) => config && typeof config === 'object' && Object.keys(config).length > 0
    )

  const DisabledProvider = ({ children }: { children: ReactNode }) => (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <div className="opacity-75 cursor-not-allowed">{children}</div>
        </TooltipTrigger>
        <TooltipContent>
          <p>Disable the current provider to enable this one</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )

  return (
    <SettingsLayout>
      <Settings.Title title="Single Sign-On" />
      <Settings.Description>
        Configure external data store providers by linking your account to third-party services.
      </Settings.Description>
      {wasLoaded && (
        <div className="space-y-8">
          {!activeProvider && configuration && (
            <div className="grid grid-cols-2 gap-6 auto-rows-min">
              <AzureAD configuration={configuration.azure} {...props('azure')} />
              <Okta configuration={configuration.okta} {...props('okta')} />
              <OIDC configuration={configuration.oidc} {...props('oidc')} />
            </div>
          )}

          {activeProvider && (
            <>
              <div>
                <div className="grid grid-cols-2 gap-6 lg:grid-cols-3 auto-rows-min">
                  {activeProvider[0] === 'azure' && (
                    <AzureAD disabled={false} configuration={configuration?.azure} {...props('azure')} />
                  )}
                  {activeProvider[0] === 'okta' && (
                    <Okta disabled={false} configuration={configuration?.okta} {...props('okta')} />
                  )}
                  {activeProvider[0] === 'oidc' && (
                    <OIDC disabled={false} configuration={configuration?.oidc} {...props('oidc')} />
                  )}
                </div>
              </div>

              <div>
                <h3 className="mb-4 text-lg font-medium">Other Available Providers</h3>
                <div className="grid grid-cols-2 gap-6 lg:grid-cols-3 auto-rows-min">
                  {activeProvider[0] !== 'azure' && (
                    <DisabledProvider>
                      <AzureAD disabled={true} configuration={configuration?.azure} {...props('azure')} />
                    </DisabledProvider>
                  )}
                  {activeProvider[0] !== 'okta' && (
                    <DisabledProvider>
                      <Okta disabled={true} configuration={configuration?.okta} {...props('okta')} />
                    </DisabledProvider>
                  )}
                  {activeProvider[0] !== 'oidc' && (
                    <DisabledProvider>
                      <OIDC disabled={true} configuration={configuration?.oidc} {...props('oidc')} />
                    </DisabledProvider>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </SettingsLayout>
  )
}

export default SingleSignOn
