import './util/sentry'

import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router'
import { Toaster } from 'sonner'

import { store } from '@/store'
import { createRoot } from 'react-dom/client'

import App from './App.tsx'
import './index.css'

window.isZoom = window.navigator.userAgent.includes('ZoomApps')
window.isStandalone = !window.isZoom

createRoot(document.getElementById('root')!).render(
  <>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
    <Toaster richColors />
  </>
)
