import { FC } from 'react'

import SettingsLayout from '@/components/layout/settings-layout'
import Settings from '@/components/settings'
import TwilioConfiguration from '@/pages/settings/sms-configuration/twilio'
import fadeIn from '@/util/fadeIn'

const SMSConfiguration: FC = () => {
  return (
    <SettingsLayout>
      <Settings.Title title="SMS Configuration" />
      <Settings.Description>
        We use our shared Twilio account by default. <br /> Configure your own Twilio account below to use your custom
        phone number.
      </Settings.Description>

      <div className={fadeIn()}>
        <TwilioConfiguration />
      </div>
    </SettingsLayout>
  )
}

export default SMSConfiguration
