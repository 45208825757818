import { FC, useCallback, useRef, useState } from 'react'

import SettingsLayout from '@/components/layout/settings-layout'
import Settings from '@/components/settings'
import InviteAdmin from '@/pages/settings/admin-management/Invite'
import fadeIn from '@/util/fadeIn'
import list from '@/watch-tower/pages/accounts/list'

import Admins from './Admins'
import Invitations, { Ref } from './Invitations'

const AdminManagement: FC = () => {
  const [loadedCount, setLoadedCount] = useState(0)
  const ref = useRef<Ref>(null)

  const handleLoaded = useCallback(() => setLoadedCount(c => c + 1), [])

  return (
    <SettingsLayout>
      <Settings.Title title="Admin Management">
        <InviteAdmin key={list?.length} onCreate={invite => ref.current?.onAdd(invite)} />
      </Settings.Title>

      <div className={fadeIn(loadedCount >= 2)}>
        <Admins onLoaded={handleLoaded} />

        <h4 className="pl-2 mt-6 mb-4 font-medium">Pending Invitations</h4>
        <Invitations ref={ref} onLoaded={handleLoaded} />
      </div>
    </SettingsLayout>
  )
}

export default AdminManagement
