import { FC } from 'react'

import OktaLogo from '@/assets/logos/okta.svg?react'
import SettingsCard from '@/components/settings-card/settings-card'
import { SSO } from '@/out/single-sign-on'
import { SSOProps } from '@/pages/settings/single-sign-on'
import OktaDetails from '@/pages/settings/single-sign-on/okta/details'
import OktaForm from '@/pages/settings/single-sign-on/okta/form'

type Props = SSOProps<'okta'> & {
  configuration: SSO['okta']
  disabled?: boolean
}

const Okta: FC<Props> = ({ configuration, setupTimer, onSetup, removeTimer, onRemove, disabled = false }) => {
  return (
    <SettingsCard<'sso'>
      title="Okta"
      description="Set up Okta to be the identity provider for single sign-on."
      icon={<OktaLogo className="w-6 h-6 text-[#252F3E] dark:text-white" />}
      configuration={configuration}
      onRemove={() => onRemove('okta')}
      removeTimer={removeTimer}
      disabled={disabled}
    >
      {configuration ? (
        <OktaDetails configuration={configuration} timer={removeTimer} onRemove={() => onRemove('okta')} />
      ) : (
        <OktaForm timer={setupTimer} onSetup={config => onSetup('okta', config)} />
      )}
    </SettingsCard>
  )
}

export default Okta
