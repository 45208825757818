import { cn } from '@/lib/utils'
import { FC, ReactNode } from 'react'
import LayoutHeader from './header'

type Props = {
  children: ReactNode
  className?: string
}

const LayoutMain: FC<Props> = ({ children, className }) => {
  return (
    <main className={cn('flex flex-col flex-1 bg-muted/50 w-full min-h-screen text-balance', className)}>
      <LayoutHeader />
      {children}
    </main>
  )
}

export default LayoutMain
