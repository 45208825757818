import { FC, useEffect } from 'react'

import useList from '@/hooks/useList'
import { Admin } from '@/out/admin'
import { listAdmins, Request } from '@/thunks/admins/list'

import AdminTable from './Table'

type Props = {
  onLoaded: () => void
}

const Admins: FC<Props> = ({ onLoaded }) => {
  const [admins, { wasLoaded, patch, remove }] = useList<Admin, Request>(listAdmins, {})

  useEffect(() => {
    if (!wasLoaded) return
    onLoaded()
  }, [wasLoaded, onLoaded])

  if (!admins) return null

  return <AdminTable admins={admins} onUpdate={patch} onRemove={remove} />
}

export default Admins
