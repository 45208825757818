import clsx from 'clsx'
import { ZapIcon } from 'lucide-react'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import r from 'routes'

import { Button } from '@/components/ui/button'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import useObject from '@/hooks/useObject'
import { useAppSelector } from '@/store'
import { billingUsage } from '@/thunks/billing/usage'

const CreditInfo: FC = () => {
  const { app } = useAppSelector()
  const navigate = useNavigate()

  const [usage] = useObject(billingUsage, {
    app: app!,
  })

  if (!usage || usage.status !== 'free-trial') return null

  return (
    <div className="flex items-center space-x-4">
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger
            className={clsx('text-sm', {
              'text-destructive font-medium': usage.credits >= usage.freeTier * 0.8,
            })}
          >
            {usage.credits} / {usage.freeTier}
          </TooltipTrigger>
          <TooltipContent side="left" sideOffset={30}>
            You&apos;ve used {usage.credits} of your {usage.freeTier} free credits.
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <Button
        variant="default"
        size="sm"
        className="group flex gap-2"
        onClick={() => navigate(r.apps[app!].settings.billing)}
      >
        <ZapIcon className="group-hover:fill-yellow-400 group-hover:stroke-yellow-400 w-3 h-3 transition-colors duration-300" />{' '}
        Upgrade
      </Button>
    </div>
  )
}

export default CreditInfo
