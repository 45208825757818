import asyncThunk from '@/lib/asyncThunk'
import { AdminRole } from '@/out/admin'

type Request = {
  id: string
  role: AdminRole
}

type Response = {
  id: string
  role: AdminRole
}

export const updateInvitationRole = asyncThunk<Request, Response>({
  name: 'invitations/role',
  method: 'PUT',
  uri: ({ id }) => `/invitations/${id}/role`,
  body: ({ id: _, ...rest }) => rest,
})
