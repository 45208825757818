import { format, parseISO } from 'date-fns'
import { ArrowLeftToLine, ChevronRight, Cog, DownloadIcon } from 'lucide-react'
import { FC, useCallback, useState } from 'react'

import apps, { appIcons, appNames } from '@/apps'
import SettingsLayout from '@/components/layout/settings-layout'
import LoadingButton from '@/components/loading-button'
import SettingsTitle from '@/components/settings-title'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { useAPI } from '@/hooks/useAPI'
import useDownload from '@/hooks/useDownload'
import useList from '@/hooks/useList'
import { exportAuditLog } from '@/thunks/audit-log/export'
import { auditLog } from '@/thunks/audit-log/list'
import fadeIn from '@/util/fadeIn'

const AuditLog: FC = () => {
  const [lastKey, setLastKey] = useState<string>()
  const [events, { headers }] = useList(auditLog, { lastKey })

  const [expo, { timer }] = useAPI(exportAuditLog)
  const download = useDownload()
  const handleExport = useCallback(async () => {
    const [csv] = await expo({
      lastKey,
      timezone: window.Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    const filename = `audit-log-${format(new Date(), 'yyyy-mm-dd')}.csv`
    download('csv', filename, csv)
  }, [lastKey, download, expo])

  return (
    <SettingsLayout className="max-w-7xl">
      <SettingsTitle title="Audit log">
        <LoadingButton timer={timer} size="sm" variant="outline" onClick={handleExport}>
          <DownloadIcon className="w-4 h-4 mr-2" />
          Export
        </LoadingButton>
      </SettingsTitle>
      {events && (
        <div className={fadeIn()}>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="pl-4">Date</TableHead>
                <TableHead>Admin</TableHead>
                <TableHead>Description</TableHead>
                <TableHead>Event</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {events &&
                events.map((event, i) => (
                  <TableRow key={i} data-id={event.id}>
                    <TableCell className="pl-4 whitespace-nowrap">
                      {format(parseISO(event.timestamp), 'MMM d, yyyy h:mma')}
                    </TableCell>
                    <TableCell>
                      <div className="w-[80px] whitespace-nowrap">
                        {event.admin ? `${event.admin.firstName} ${event.admin.lastName}` : 'Journey'}
                      </div>
                    </TableCell>
                    <TableCell className="flex items-center space-x-2">
                      {(() => {
                        if (event.namespace === 'global') {
                          return (
                            <TooltipProvider>
                              <Tooltip>
                                <TooltipTrigger>
                                  <div className="p-1 rounded-md bg-gray-50">
                                    <Cog className="w-5 h-5 text-muted-foreground" />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>Account</TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          )
                        }
                        const Icon = appIcons[event.namespace]
                        const color = apps.find(a => a.id === event.namespace)?.color || 'blue'
                        return (
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <div className={`p-1 rounded-md bg-${color}-50`}>
                                  <Icon className={`w-5 h-5 text-${color}-500`} />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent>{appNames[event.namespace]}</TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        )
                      })()}
                      <span className="max-w-[400px] font-medium text-foreground truncate">{event.description}</span>
                    </TableCell>
                    <TableCell>{event.event}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div className="flex items-center justify-between px-2 mt-2">
            <div className="flex-1 text-sm text-muted-foreground">
              {events?.length} of {headers?.['x-count']} events
            </div>
            <div className="flex items-center space-x-4">
              <button
                className="inline-flex items-center justify-center w-8 h-8 p-0 text-sm font-medium transition-colors border rounded-md shadow-sm bg-background hover:bg-accent disabled:opacity-50 border-input focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring whitespace-nowrap hover:text-accent-foreground disabled:pointer-events-none"
                onClick={() => setLastKey(undefined)}
                disabled={!lastKey}
              >
                <span className="sr-only">Go to next page</span>
                <ArrowLeftToLine className="w-4 h-4" />
              </button>
              <button
                className="inline-flex items-center justify-center w-8 h-8 p-0 text-sm font-medium transition-colors border rounded-md shadow-sm bg-background hover:bg-accent disabled:opacity-50 border-input focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring whitespace-nowrap hover:text-accent-foreground disabled:pointer-events-none"
                onClick={() => headers && setLastKey(headers['x-last-key'])}
                disabled={headers && !headers['x-last-key']}
              >
                <span className="sr-only">Go to next page</span>
                <ChevronRight className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      )}
    </SettingsLayout>
  )
}

export default AuditLog
