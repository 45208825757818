import { FC } from 'react'

import OIDCLogo from '@/assets/logos/oidc.svg?react'
import SettingsCard from '@/components/settings-card/settings-card'
import { SSO } from '@/out/single-sign-on'
import { SSOProps } from '@/pages/settings/single-sign-on'
import OIDCDetails from '@/pages/settings/single-sign-on/oidc/details'
import OIDCForm from '@/pages/settings/single-sign-on/oidc/form'

type Props = SSOProps<'oidc'> & {
  configuration: SSO['oidc']
  disabled?: boolean
}

const OIDC: FC<Props> = ({ configuration, setupTimer, onSetup, removeTimer, onRemove, disabled = false }) => {
  return (
    <SettingsCard<'sso'>
      title="OIDC"
      description="Set up an OIDC provider for single sign-on."
      icon={<OIDCLogo className="w-6 h-6 text-[#252F3E] dark:text-white" />}
      configuration={configuration}
      onRemove={() => {
        onRemove('oidc')
      }}
      removeTimer={removeTimer}
      disabled={disabled}
    >
      {configuration ? (
        <OIDCDetails
          configuration={configuration}
          timer={removeTimer}
          onRemove={() => {
            onRemove('oidc')
          }}
        />
      ) : (
        <OIDCForm timer={setupTimer} onSetup={config => onSetup('oidc', config)} />
      )}
    </SettingsCard>
  )
}

export default OIDC
