import { App } from '@/apps'
import asyncThunk from '@/lib/asyncThunk'
import { AdminRole } from '@/out/admin'

type Request = Record<never, never>

export type AppStatus = 'inactive' | 'free-trial' | 'subscription' | 'enterprise' | 'cancelled'

export function isFreeTrial(config: AppConfig): config is FreeTrial {
  return config.status === 'free-trial'
}

export type FreeTrial = {
  status: 'free-trial'
  credits: number
  freeTier: number
}

type ActiveSubscription = {
  status: 'subscription'
  credits: number
}

export type AppConfig<T extends AppStatus = AppStatus> = FreeTrial | ActiveSubscription | { status: T }

export type Response = {
  id: string
  firstName: string | null
  lastName: string | null
  email: string
  role: AdminRole
  billing: Record<App, AppConfig>
  account: {
    id: string
    name: string
  }
}

export const bootstrapAdmin = asyncThunk<Request, Response>({
  name: 'admins/bootstrap',
  uri: '/admins/bootstrap',
})
