import { Globe } from 'lucide-react'
import { FC, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router'
import r from 'routes'

import LoadingButton from '@/components/loading-button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'
import { useAPI } from '@/hooks/useAPI'
import { isErrorResponse } from '@/lib/asyncThunk'
import { Branding } from '@/out/branding'
import { updateDomain } from '@/thunks/branding/domain'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { CopyButton } from '@/components/copy-button'

type Props = {
  branding: Branding
  onSuccess: (branding: Branding) => void
  onRefresh: () => void
}

const CustomDomainCard: FC<Props> = ({ branding, onSuccess, onRefresh }) => {
  const [domain, setDomain] = useState('')

  const [error, setError] = useState<string>()
  const [setup, { timer }] = useAPI(updateDomain)
  const handleSubmit = useCallback(async () => {
    try {
      setError(undefined)

      const [branding] = await setup({ domain })
      onSuccess(branding)
    } catch (err) {
      if (isErrorResponse(err) && err.code === 'DNS_INVALID') {
        setError("DNS settings are invalid or haven't propagated yet")
      } else {
        setError('An error occurred while verifying the DNS settings')
      }
    }
  }, [setup, domain, onSuccess])

  const [countdown, setCountdown] = useState(15)
  useEffect(() => {
    if (!branding.domain || branding.domainIsVerified) return

    const t = setInterval(() => {
      setCountdown(prev => {
        if (prev === 1) {
          onRefresh()
          return 15
        }
        return prev - 1
      })
    }, 1_000)

    return () => clearInterval(t)
  }, [branding, onRefresh])

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-3">
          <Globe className="w-5 h-5 text-primary" />
          <span>Branded Domain</span>
        </CardTitle>
        <CardDescription>Configure a custom domain for your customers&apos; branded experience</CardDescription>
      </CardHeader>

      <Separator />

      <CardContent className="gap-8 grid pt-6">
        {branding.domain ? (
          <div className="space-y-4">
            {branding.domainIsVerified ? (
              <div>
                <Alert className="bg-green-50/80 border-green-100 text-green-600">
                  <AlertTitle className="flex items-center gap-2">
                    <div className="bg-green-500 rounded-full w-2.5 h-2.5 animate-pulse" />
                    <span className="font-mono">{branding.domain}</span>
                    <span className="text-green-600/75">is verified and active</span>
                  </AlertTitle>
                  <AlertDescription className="mt-2">
                    Your domain will now appear in the links sent to your customers via SMS as well as in your
                    customers&apos; browser.
                  </AlertDescription>
                </Alert>
              </div>
            ) : (
              <Alert className="bg-blue-50/80 border-blue-100 text-blue-700">
                <AlertTitle className="flex items-center gap-2">
                  <div className="border-2 border-t-transparent border-blue-500 rounded-full w-4 h-4 animate-spin" />
                  <span>Verifying {branding.domain}...</span>
                </AlertTitle>
                <AlertDescription className="text-blue-600/75">
                  Next check in {countdown} seconds. This generally takes 30-60 seconds.
                </AlertDescription>
              </Alert>
            )}
          </div>
        ) : (
          <>
            <section>
              <Label className="inline-block bg-muted px-4 py-2 rounded-lg font-semibold text-primary text-base">
                Step 1: Configure DNS
              </Label>

              <div className="my-4 p-4 border rounded-lg">
                <div className="flex justify-between items-center gap-2">
                  <div className="bg-primary-foreground p-4 rounded-md">
                    <Label className="text-muted-foreground text-xs uppercase">Type</Label>
                    <div className="flex items-center gap-2 mt-1">
                      <p className="font-mono">CNAME</p>
                      <CopyButton text="CNAME" small />
                    </div>
                  </div>
                  <div className="flex-1 bg-primary-foreground p-4 rounded-md">
                    <Label className="text-muted-foreground text-xs uppercase">Value</Label>
                    <div className="flex items-center gap-2 mt-1">
                      <p className="font-mono">{import.meta.env.VITE_CUSTOMER_PROXY_DOMAIN}</p>
                      <CopyButton text={import.meta.env.VITE_CUSTOMER_PROXY_DOMAIN} small />
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <Label className="inline-block bg-muted px-4 py-2 rounded-lg font-semibold text-primary text-base">
                  Step 2: Enter your domain
                </Label>

                <Input
                  id="domain"
                  placeholder="cx.example.com"
                  value={domain}
                  autoComplete="one-time-code"
                  onChange={e => setDomain(e.target.value)}
                  required
                />
              </div>

              <div className="space-y-4 mt-6">
                {error && (
                  <Alert variant="destructive">
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                )}
                <div className="flex items-center gap-4">
                  <LoadingButton
                    timer={timer}
                    onClick={handleSubmit}
                    disabled={!domain.match(/^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/)}
                  >
                    Verify DNS settings
                  </LoadingButton>

                  <Alert className="bg-amber-50/80 border-amber-100 text-amber-600">
                    <AlertDescription>⚠️ DNS changes can take up to 48 hours to propagate globally</AlertDescription>
                  </Alert>
                </div>
              </div>
            </section>
          </>
        )}
      </CardContent>

      <CardFooter className="gap-2 text-sm">
        Need help?
        <Link
          to={r.support.root}
          className="font-medium text-primary hover:text-primary/80 hover:underline transition-colors"
        >
          Contact our support team →
        </Link>
      </CardFooter>
    </Card>
  )
}

export default CustomDomainCard
