import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import { Link } from 'react-router'

type Props = {
  children: ReactNode
}

const SettingsNav: FC<Props> = ({ children }) => {
  return (
    <div className="min-h-screen border-r bg-background border-border w-80">
      <div className="sticky px-4 py-4 top-16">
        <nav className="flex flex-col gap-1.5">{children}</nav>
      </div>
    </div>
  )
}

type ItemProps = {
  to: string
  isActive: boolean
  children: ReactNode
}

const NavLink: FC<ItemProps> = ({ to, isActive, children }) => {
  return (
    <Link
      to={to}
      className={clsx(
        'px-3 py-2 rounded-md text-sm transition-colors flex items-center [&>svg]:w-4 [&>svg]:h-4 [&>svg]:mr-2',
        isActive
          ? 'text-foreground bg-muted font-medium shadow-sm'
          : 'text-muted-foreground hover:text-foreground hover:bg-muted/50'
      )}
    >
      {children}
    </Link>
  )
}

const SettingsContainer: FC<{ children: ReactNode }> = ({ children }) => <div className="flex w-full">{children}</div>

export default SettingsNav
export { NavLink, SettingsContainer }
