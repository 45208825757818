import { UserPlus } from 'lucide-react'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Label } from 'recharts'
import { z } from 'zod'

import { zodResolver } from '@hookform/resolvers/zod'

import LoadingButton from '@/components/loading-button'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Invitation } from '@/out/invitation'

import useSendInvitation from './useSendInvitation'

const schema = z
  .object({
    email: z.string().email(),
  })
  .required()

type FormData = z.infer<typeof schema>

type Props = {
  onCreate: (invitation: Invitation) => void
}

const InviteAdmin: FC<Props> = ({ onCreate }) => {
  const [isOpen, setIsOpen] = useState(false)

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: '',
    },
    mode: 'onSubmit',
  })

  const [sendInvitation, { timer }] = useSendInvitation(i => {
    onCreate(i)
    setIsOpen(false)
    form.reset()
  })

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">
          <UserPlus className="w-4 h-4 mr-2" />
          Invite an Admin
        </Button>
      </DialogTrigger>
      <DialogContent className="space-y-4">
        <form onSubmit={form.handleSubmit(sendInvitation)}>
          <DialogHeader>
            <DialogTitle>Add New Admin</DialogTitle>
            <DialogDescription>
              Invite a new admin by entering their email address. They will receive an invitation to join your
              organization.
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-2">
            <Label>Email</Label>
            <Input {...form.register('email')} autoComplete="one-time-code" placeholder="Enter admin email" />
            {form.formState.errors.email && (
              <p className="text-sm text-red-500">{form.formState.errors.email.message}</p>
            )}
          </div>
          <DialogFooter>
            <LoadingButton timer={timer} type="submit">
              Add Admin
            </LoadingButton>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default InviteAdmin
