import clsx from 'clsx'
import { FC, useMemo } from 'react'
import { Link, useLocation } from 'react-router'

import { appNavigation } from '@/app-navigation'
import { useAppSelector, useAuthSelector } from '@/store'

function isActive(location: string, path: string) {
  if (location === path) return true
  if (!path.match(/\/.+\//)) return false
  return location.startsWith(path)
}

const DesktopNav: FC = () => {
  const { app } = useAppSelector()
  const { pathname } = useLocation()
  const { role } = useAuthSelector()

  const items = useMemo(() => role && app && appNavigation(role)[app].filter(item => !item.hidden), [app, role])

  return (
    <nav className="flex flex-1 gap-2">
      {items?.map(item => (
        <Link
          key={item.title}
          to={item.path}
          className={clsx(
            'px-5 py-2 rounded-lg text-sm whitespace-nowrap transition-all duration-200',
            isActive(pathname, item.path)
              ? 'text-foreground border'
              : 'text-muted-foreground hover:text-foreground hover:bg-muted/30 border border-transparent'
          )}
        >
          {item.title}
        </Link>
      ))}
    </nav>
  )
}

export default DesktopNav
