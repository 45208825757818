import { Disc3, EllipsisVertical } from 'lucide-react'
import { FC, useCallback } from 'react'

import { App, appNames } from '@/apps'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { useAPI } from '@/hooks/useAPI'
import { AppStatus } from '@/thunks/admins/bootstrap'
import objectKeys from '@/util/object-keys'
import { Response } from '@/watch-tower/thunks/accounts/retrieve'
import { setAppStatus } from '@/watch-tower/thunks/apps/status'

const statusNames: Record<AppStatus, string> = {
  'free-trial': 'Free Trial',
  subscription: 'Subscription',
  enterprise: 'Enterprise',
  cancelled: 'Cancelled',
  inactive: 'Inactive',
}

type Props = {
  accountId: string
  apps: Record<App, Response['apps'][App]>
  onUpdate: (app: App, status: AppStatus) => void
}

const AppsTable: FC<Props> = ({ accountId, apps, onUpdate }) => {
  const [setStatus, { timer }] = useAPI(setAppStatus)
  const handleStatus = useCallback(
    async (app: App) => {
      timer.setId(app)
      const [response] = await setStatus({ accountId, app })
      onUpdate(app, response.status)
    },
    [accountId, timer, setStatus, onUpdate]
  )

  return (
    <div className="border rounded-md">
      <Table>
        <TableHeader>
          <TableRow className="font-semibold text-foreground bg-muted/30 hover:bg-muted/30">
            <TableHead className="w-2/3">App</TableHead>
            <TableHead className="w-1/3">Status</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {objectKeys(apps).map(app => (
            <TableRow key={app}>
              <TableCell>{appNames[app]}</TableCell>
              <TableCell>
                {timer.id === app && timer.isLoading ? (
                  <Disc3 className="w-4 h-4 animate-spin" />
                ) : (
                  statusNames[apps[app].status]
                )}
              </TableCell>
              <TableCell className="w-8 text-right text-muted-foreground">
                {apps[app].status !== 'enterprise' && (
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <EllipsisVertical className="w-4 h-4" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuItem onClick={() => handleStatus(app)}>Convert to Enterprise</DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default AppsTable
