import { Check, Loader2, Pencil } from 'lucide-react'
import { FC, useCallback, useState } from 'react'

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useAPI } from '@/hooks/useAPI'
import { Admin, AdminRole } from '@/out/admin'
import { updateAdminRole } from '@/thunks/admins/role'
import { updateInvitationRole } from '@/thunks/invitations/role'

const roleTitles = {
  owner: 'Owner',
  admin: 'Administrator',
  supervisor: 'Supervisor',
}

type Props = {
  admin: Pick<Admin, 'id' | 'role'>
  thunk: typeof updateAdminRole | typeof updateInvitationRole
  onChange: (role: AdminRole) => void
}

const RoleSelect: FC<Props> = ({ admin, thunk, onChange }) => {
  const [isEditing, setIsEditing] = useState(false)

  const [update, { timer }] = useAPI(thunk)
  const handleChange = useCallback(
    async (role: AdminRole) => {
      timer.setId(role)
      const [updated] = await update({
        id: admin.id,
        role,
      })
      setTimeout(() => {
        onChange(updated.role)
        setIsEditing(false)
      }, 300)
    },
    [admin, timer, onChange, update]
  )

  if (timer.isLoading || timer.didSucceed) {
    return (
      <div className="inline-flex items-center gap-2 py-1" onClick={() => setIsEditing(true)}>
        {roleTitles[timer.id as AdminRole]}
        {timer.isLoading ? (
          <Loader2 size={18} className="text-muted-foreground animate-spin " />
        ) : (
          <Check size={18} className="text-green-600" />
        )}
      </div>
    )
  }

  if (!isEditing) {
    return (
      <div className="inline-flex items-center gap-2 py-1 cursor-pointer group" onClick={() => setIsEditing(true)}>
        {roleTitles[admin.role]}
        <Pencil size={14} className="opacity-0 cursor-pointer text-muted-foreground group-hover:opacity-100" />
      </div>
    )
  }

  return (
    <div className="flex items-center gap-3 -mt-0.5 -ml-[13px]">
      <Select open value={admin.role} onValueChange={handleChange} onOpenChange={() => setIsEditing(false)}>
        <SelectTrigger className="items-start w-40">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="admin" className="cursor-pointer">
            Administrator
          </SelectItem>
          <SelectItem value="supervisor" className="cursor-pointer">
            Supervisor
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
  )
}

export default RoleSelect
