import { FC } from 'react'
import { NonUndefined } from 'react-hook-form'

import LoadingButton from '@/components/loading-button'
import { ActionTimer } from '@/hooks/useActionTimer'
import { SSO } from '@/out/single-sign-on'

type Props = {
  configuration: NonUndefined<SSO['oidc']>
  timer: ActionTimer
  onRemove: () => void
}

const OIDCDetails: FC<Props> = ({ configuration, timer, onRemove }) => {
  return (
    <div>
      <div className="pt-2 mb-6 space-y-3">
        <div className="flex flex-col space-y-1">
          <span className="text-sm text-muted-foreground">OpenID Issuer</span>
          <span className="text-sm font-medium">{configuration.issuer}</span>
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-sm text-muted-foreground">Email Domain</span>
          <span className="text-sm font-medium">{configuration.domain}</span>
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-sm text-muted-foreground">Client ID</span>
          <span className="text-sm font-medium">{configuration.clientId}</span>
        </div>
      </div>
      <LoadingButton
        timer={timer}
        variant="secondary"
        size="sm"
        onClick={onRemove}
        className="text-xs hover:bg-destructive hover:text-background"
      >
        Disconnect
      </LoadingButton>
    </div>
  )
}

export default OIDCDetails
