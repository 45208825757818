import clsx from 'clsx'
import { format, isAfter, isBefore } from 'date-fns'
import { FC } from 'react'

import { Badge } from '@/components/ui/badge'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Invitation } from '@/out/invitation'
import { updateInvitationRole } from '@/thunks/invitations/role'

import RevokeInvitationDialog from '../Invitations/RevokeDialog'
import RoleSelect from '../Shared/RoleSelect'

type Props = {
  invitations: Invitation[]
  onUpdate: (invitation: Partial<Invitation> & { id: string }) => void
  onRevoke: (id: string) => void
}

const InvitationsTable: FC<Props> = ({ invitations, onUpdate, onRevoke }) => {
  return (
    <Table>
      <TableHeader>
        <TableRow className="hover:bg-inherit">
          <TableHead className="w-56 pl-4">Name</TableHead>
          <TableHead className="w-80">Email</TableHead>
          <TableHead></TableHead>
          <TableHead className="w-56">Role</TableHead>
          <TableHead className="w-48"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {invitations.map(invitation => (
          <TableRow key={invitation.id} data-id={invitation.id} className="h-14">
            <TableCell className="pl-4">
              <span className="italic text-muted-foreground">unknown</span>
            </TableCell>
            <TableCell>{invitation.email}</TableCell>
            <TableCell>
              <Badge
                variant="outline"
                className={clsx('mr-4 text-black border-0 whitespace-nowrap', {
                  'bg-yellow-400 ': isAfter(invitation.expiresAt, new Date()),
                  'bg-red-600 text-white': isBefore(invitation.expiresAt, new Date()),
                })}
              >
                Invitation {isAfter(invitation.expiresAt, new Date()) ? 'expires' : 'expired'}{' '}
                {format(invitation.expiresAt, 'MMM d, yyyy')}
              </Badge>
            </TableCell>
            <TableCell>
              <RoleSelect
                admin={invitation}
                thunk={updateInvitationRole}
                onChange={role => onUpdate({ id: invitation.id, role })}
              />
            </TableCell>
            <TableCell className="flex items-center justify-end pr-4 space-x-4 text-right h-14">
              <RevokeInvitationDialog invitation={invitation} onRevoke={onRevoke} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default InvitationsTable
