import { forwardRef, useEffect, useImperativeHandle } from 'react'

import useList from '@/hooks/useList'
import { Invitation } from '@/out/invitation'
import { listInvitations, Request } from '@/thunks/invitations/list'

import InvitationsTable from './Table'

export type Ref = {
  onAdd: (invitation: Invitation) => void
}

type Props = {
  onLoaded: () => void
}

const Invitations = forwardRef<Ref, Props>(({ onLoaded }, ref) => {
  const [invitations, { wasLoaded, add, patch, remove }] = useList<Invitation, Request>(listInvitations, {})
  useImperativeHandle(ref, () => ({ onAdd: add }))

  useEffect(() => {
    if (!wasLoaded) return
    onLoaded()
  }, [wasLoaded, onLoaded])

  if (!invitations) return null

  return <InvitationsTable invitations={invitations} onUpdate={patch} onRevoke={remove} />
})

Invitations.displayName = 'Invitations'

export default Invitations
