import { FC, ReactNode } from 'react'

type Props = {
  title: string
  children?: ReactNode
}

const SettingsTitle: FC<Props> = ({ title, children }) => {
  return (
    <h2 className="flex flex-col items-center justify-between space-y-4 text-3xl md:space-y-0 md:flex-row">
      <span>{title}</span>
      {children && <div className="flex items-center">{children}</div>}
    </h2>
  )
}

export default SettingsTitle
