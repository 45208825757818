import { FC } from 'react'
import { Link, useNavigate } from 'react-router'
import r from 'routes'

import apps, { appColors } from '@/apps'
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Account } from '@/watch-tower/out/account'

const providerTitles = {
  azure: 'Azure',
  google: 'Google',
  zoom: 'Zoom',
  okta: 'Okta',
  oidc: 'OIDC',
}

type Props = {
  accounts: Account[] | undefined
}

const AccountTable: FC<Props> = ({ accounts }) => {
  const navigate = useNavigate()
  return (
    <Table className="w-full">
      <TableHeader className="text-xs bg-muted/30">
        <TableRow>
          <TableHead className="pl-4">Account name</TableHead>
          <TableHead className="hidden md:table-cell">Active Apps</TableHead>
          <TableHead className="pr-4 text-right">Platform</TableHead>
          {/* <TableHead>
            <div className="flex justify-end">
              Last Invoice
              <ArrowUpDown className="w-4 h-4 ml-2 text-muted-foreground/50" />
            </div>
          </TableHead>
          <TableHead>
            <div className="flex justify-end">
              LTV
              <ArrowUpDown className="w-4 h-4 ml-2 text-muted-foreground/50" />
            </div>
          </TableHead>
          <TableHead></TableHead> */}
        </TableRow>
      </TableHeader>
      <TableBody>
        {accounts === undefined ? (
          Array.from({ length: 10 }).map((_, index) => <TableRowSkeleton key={index} />)
        ) : accounts.length > 0 ? (
          accounts.map(account => (
            <TableRow
              key={account.id}
              className="cursor-pointer"
              onClick={() => navigate(r.watchTower.accounts.show(account.id))}
            >
              <TableCell className="w-72">
                <Link
                  to={r.watchTower.accounts.show(account.id)}
                  className="flex items-center p-2 font-medium transition-colors rounded-md"
                >
                  {account.name}
                </Link>
              </TableCell>
              <TableCell className="hidden md:table-cell">
                <ScrollArea className="rounded-md whitespace-nowrap max-w-[520px]">
                  <div className="flex p-1 space-x-1 w-max">
                    {account.apps.map(a => {
                      const app = apps.find(app => app.id === a.id)
                      if (!app) return null

                      return (
                        <div
                          key={app.id}
                          className="flex items-center px-2 py-1 text-xs rounded-full bg-secondary shrink-0"
                        >
                          <app.icon className={`h-3 w-3 mr-1 ${appColors[app.color]}`} />
                          {app.title}
                        </div>
                      )
                    })}
                  </div>
                  <ScrollBar orientation="horizontal" />
                </ScrollArea>
              </TableCell>
              <TableCell>
                <div className="pr-2 text-xs text-right">{providerTitles[account.provider]}</div>
              </TableCell>
              {/* <TableCell className="text-right">
                <div className="text-xs">$0</div>
              </TableCell>
              <TableCell className="text-right">
                <div className="text-xs">$0</div>
              </TableCell>
              <TableCell>Keep existing DropdownMenu component</TableCell> */}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={5} className="h-24 text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

const TableRowSkeleton = () => (
  <TableRow>
    <TableCell>
      <div className="flex flex-col items-start p-2 space-y-1">
        <Skeleton className="w-48 h-4 mb-1" />
        <Skeleton className="w-24 h-3" />
      </div>
    </TableCell>
    <TableCell>
      <Skeleton className="w-[350px] h-6" />
    </TableCell>
    <TableCell>
      <div className="flex items-center justify-end">
        <Skeleton className="w-24 h-3" />
      </div>
    </TableCell>
  </TableRow>
)

export default AccountTable
