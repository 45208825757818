import { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import r from 'routes'

import apps, { App } from '@/apps'
import BillingCard from '@/components/billing/billing-card'
import SettingsLayout from '@/components/layout/settings-layout'
import Settings from '@/components/settings'
import useObject from '@/hooks/useObject'
import { Response, retrieveBilling } from '@/thunks/billing/retrieve'
import fadeIn from '@/util/fadeIn'

const order = ['free-trial', 'subscription', 'enterprise', 'inactive']

const billingPlans = (billing: Response) =>
  apps
    .map(app => ({
      app: app.id,
      icon: app.icon,
      title: app.title,
      description: `Enhance your app with ${app.title.toLowerCase()}.`,
      config: billing.apps[app.id],
      topFeatures: [
        `${billing.freeTiers[app.id].credits} ${billing.freeTiers[app.id].units} free`,
        'One-click & custom integrations',
        'Live chat support',
      ],
      monthlyCost: -1,
      overageCost: -1,
    }))
    .sort((a, b) => {
      const aIndex = order.indexOf(a.config.status)
      const bIndex = order.indexOf(b.config.status)
      return aIndex - bIndex
    })

const Billing: FC = () => {
  const [billing] = useObject(retrieveBilling, {})

  const sortedBillingPlans = useMemo(() => !!billing && billingPlans(billing), [billing])

  const navigate = useNavigate()
  const handleClick = useCallback(
    async (app: App) => {
      if (!billing) return

      if (billing.apps[app].status === 'inactive') {
        navigate(r.apps[app].pricing)
      } else {
        navigate(r.apps[app].settings.billing)
      }
    },
    [billing, navigate]
  )

  if (!billing) return null

  return (
    <SettingsLayout>
      <Settings.Title title="Billing" />

      <div className={fadeIn()}>
        <div className="flex flex-wrap gap-4">
          {sortedBillingPlans &&
            sortedBillingPlans.map((plan, index) => (
              <BillingCard key={index} {...plan} onClick={() => handleClick(plan.app)} />
            ))}
        </div>
      </div>
    </SettingsLayout>
  )
}

export default Billing
