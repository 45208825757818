import { ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatPlanName(plan: string) {
  const [first, ...rest] = plan.split('-')
  return capitalizeFirstLetter(first) + (rest.length ? ' ' + rest.join(' ') : '')
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
