import clsx from 'clsx'
import { Check, Copy } from 'lucide-react'
import { FC, ReactNode, useState } from 'react'

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { delay } from '@/hooks/useActionTimer'
import useTimer from '@/hooks/useTimer'

type Props = {
  text: string
  children?: ReactNode
  small?: boolean
}

export const CopyButton: FC<Props> = ({ text, children, small = false }) => {
  const [wasCopied, start] = useTimer()
  const [isOpen, setIsOpen] = useState(false)

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text)
      start()
      setTimeout(() => {
        setIsOpen(false)
      }, delay - 300)
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
  }

  const defaultIcon = wasCopied ? (
    <Check className={`${small ? 'w-3 h-3' : 'w-4 h-4'} text-green-400`} />
  ) : (
    <Copy className={`${small ? 'w-3 h-3' : 'w-4 h-4'} text-muted-foreground hover:text-foreground`} />
  )

  return (
    <TooltipProvider>
      <Tooltip open={wasCopied || isOpen} delayDuration={0}>
        <TooltipTrigger
          className={`flex justify-center items-center hover:bg-muted rounded ${small ? 'p-1.5' : 'p-2 rounded'}`}
          onClick={handleCopy}
          onMouseEnter={() => !wasCopied && setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {children ?? defaultIcon}
        </TooltipTrigger>
        <TooltipContent
          className={clsx({
            'text-white bg-green-600': wasCopied,
          })}
        >
          <p className={small ? 'text-xs' : undefined}>{wasCopied ? 'Copied!' : 'Click to copy'}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
