export type AdminRole = 'owner' | 'admin' | 'supervisor'

export type Admin = {
  id: string
  firstName?: string
  lastName?: string
  email: string
  role: AdminRole
}

export function isAdmin(role: AdminRole | undefined) {
  return role === 'admin' || role === 'owner'
}
