import clsx from 'clsx'
import { Zap } from 'lucide-react'
import { FC, useCallback } from 'react'
import { Link, useNavigate } from 'react-router'
import r from 'routes'

import apps, { App, appIcons } from '@/apps'
import LoadingButton from '@/components/loading-button'
import { Card } from '@/components/ui/card'
import { idleTimer } from '@/hooks/useActionTimer'
import { useAPI } from '@/hooks/useAPI'
import { useBillingSelector } from '@/store'
import { startFreeTrial } from '@/thunks/billing/free-trial'
import { Button } from '@/components/ui/button'
import AppIcon from '@/components/app-icon'

const appIds = ['passkeys', 'one-time-passcode', 'document-signature', 'e-form', 'payments'] as const

const appCards = appIds.map(appId => {
  const app = apps.find(app => app.id === appId)
  if (!app) throw new Error(`App not found: ${appId}`)

  return {
    app: appId,
    icon: appIcons[appId],
    title: app.title,
    color: app.color,
  }
})

const descriptions = {
  passkeys: 'Authenticate customers with their smartphone.',
  'document-signature': 'Request customers to sign documents.',
  'one-time-passcode': 'Update customer authentication in a few hours.',
  payments: 'Accept digital payments in the contact center.',
  'e-form': 'Collect sensitive data without exposing it to agents.',
} as const

const Home: FC = () => {
  const navigate = useNavigate()
  const [start, { timer }] = useAPI(startFreeTrial)

  const apps = useBillingSelector()

  const handleStartFreeTrial = useCallback(
    async (app: App) => {
      timer.setId(app)
      await start({ app })
      navigate(r.apps[app].root)
    },
    [start, timer, navigate]
  )

  return (
    <div className="mx-auto px-4 py-12 w-full md:max-w-7xl">
      <div className="gap-8 grid sm:grid-cols-2 lg:grid-cols-3">
        {appCards.map(({ app, icon: Icon, ...card }) => (
          <Card
            key={card.title}
            className="group relative flex flex-col hover:shadow-lg border-border/80 hover:border-border transition-all duration-300 cursor-pointer"
            onClick={() => navigate(r.apps[app].root)}
          >
            <div className="flex-grow space-y-6 p-6">
              <div className="flex justify-between items-start">
                <div className="flex items-start gap-6">
                  <AppIcon Icon={Icon} color={card.color} />
                  <div>
                    <h3 className="font-medium text-lg tracking-tight">{card.title}</h3>
                    <p className="text-muted-foreground/80 text-sm leading-relaxed">{descriptions[app]}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-b from-transparent to-muted/10 p-4">
              {apps[app].status === 'inactive' ? (
                <LoadingButton
                  timer={timer.id === app ? timer : idleTimer}
                  variant="outline"
                  className={clsx(
                    'w-full h-10',
                    'bg-background/80 backdrop-blur-sm',
                    'hover:bg-b ackground hover:border-border',
                    'font-medium text-sm',
                    'transition-all duration-300'
                  )}
                  onClick={() => handleStartFreeTrial(app)}
                >
                  <span className="flex items-center gap-2">
                    <Zap className="w-4 h-4 text-primary" />
                    Start Free Trial
                  </span>
                </LoadingButton>
              ) : (
                <Button
                  variant="outline"
                  size={'lg'}
                  className={`w-full shadow-none hover:bg-${card.color}-500 transition-all duration-300 font-sm`}
                >
                  <Link to={r.apps[app].root}>Launch app</Link>
                </Button>
              )}
            </div>
          </Card>
        ))}
      </div>
    </div>
  )
}

export default Home
