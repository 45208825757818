import { clsx } from 'clsx'
import { FC, useMemo } from 'react'
import { Link } from 'react-router'
import r from 'routes'

import apps, { appColors, Apps } from '@/apps'
import {
  NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu'
import { cn } from '@/lib/utils'
import { useAppSelector } from '@/store'

const AppSelector: FC = () => {
  const { app } = useAppSelector()
  const activeApp = useMemo(() => apps.find(a => a.id === app), [app])

  return (
    <NavigationMenu delayDuration={0}>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger
            className="justify-start w-64 pl-3 [&>svg:last-child]:ml-auto bg-transparent cursor-default border"
            onClick={e => e.preventDefault()}
          >
            {activeApp ? (
              <>
                {<activeApp.icon className={clsx('mr-2 size-5', appColors[activeApp.color])} />}
                {activeApp.title}
              </>
            ) : (
              'Select an app'
            )}
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="w-64">
              {apps.map(app => (
                <ListItem key={app.id} app={app} isCurrent={activeApp?.id === app.id}></ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}

type ItemProps = {
  app: Apps[number]
  isCurrent?: boolean
  className?: string
}

const ListItem: FC<ItemProps> = ({ app, isCurrent, className }) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <Link
          to={r.apps[app.id].root}
          className={cn(
            'block select-none space-y-1 first-child:rounded-t-md last-child:rounded-b-md px-3 py-2 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
            { 'opacity-50 pointer-events-none': isCurrent },
            className
          )}
        >
          <div className="flex items-center text-foreground">
            <app.icon className={clsx('mr-2 size-5', appColors[app?.color])} />
            <span className="text-sm">{app.title}</span>
          </div>
        </Link>
      </NavigationMenuLink>
    </li>
  )
}

export default AppSelector
