import * as Sentry from '@sentry/react'

import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AdminRole } from '@/out/admin'
import { bootstrapAdmin } from '@/thunks/admins/bootstrap'

type AuthState = {
  token: string | undefined
  role?: AdminRole
  isRoot?: true
}

const initialState: AuthState = {
  token: localStorage.getItem('token') as string,
}

const setJWT: CaseReducer<AuthState, PayloadAction<string>> = (_, action) => {
  localStorage.setItem('token', action.payload)
  return {
    token: action.payload,
  }
}

const clearJWT: CaseReducer<AuthState> = () => {
  localStorage.removeItem('token')
  Sentry.setUser(null)
  return {
    token: undefined,
  }
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setJWT,
    clearJWT,
  },
  extraReducers: builder => {
    builder.addCase(bootstrapAdmin.fulfilled, (state, action) => {
      const { data } = action.payload

      Sentry.setUser({
        id: data.id,
        accountId: data.account.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
      })

      if ('isRoot' in data && data.isRoot) {
        state.isRoot = true
      }
      state.role = data.role
      return state
    })
  },
})

export default slice

export const actions = slice.actions
