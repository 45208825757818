import { CircleArrowLeft } from 'lucide-react'
import { FC, useMemo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router'
import r from 'routes'

import Logo from '@/assets/journey-logo.svg'
// import MobileNav from './mobile-nav'
import AppSelector from '@/components/layout/app-selector'
import { isAdmin } from '@/out/admin'
import { useAppSelector, useAuthSelector, useBillingSelector } from '@/store'

import { Button } from '../ui/button'
import AccountDropdown from './account-dropdown'
import CreditInfo from './credit-info'
import DesktopNav from './desktop-nav'
import GhostMode from './ghost'
import InactiveAppNav from './inactive-app-nav'

const LayoutHeader: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { app } = useAppSelector()
  const billing = useBillingSelector()
  const { role } = useAuthSelector()

  const status = useMemo(() => (app && app in billing ? billing[app].status : undefined), [app, billing])

  return (
    <header className="sticky top-0 z-50 flex items-center gap-3 p-3 border-b bg-background border-border">
      <Link to={r.root}>
        <img src={Logo} alt="Journey" className="mx-4 p-0.5 h-6" />
      </Link>

      {pathname.startsWith(r.settings.root) ? (
        <div
          className="flex items-center space-x-2 cursor-pointer h-9 text-foreground/70 hover:text-foreground"
          onClick={() => navigate(r.root)}
        >
          <CircleArrowLeft size={16} />
          <span className="text-sm font-medium">Back to apps</span>
        </div>
      ) : pathname !== r.root ? (
        <AppSelector />
      ) : null}

      {status !== 'inactive' ? <DesktopNav /> : <InactiveAppNav />}

      {isAdmin(role) && (
        <div className="flex items-center gap-2">
          {status === 'free-trial' && <CreditInfo />}

          <Button size="sm" variant="outline" onClick={() => navigate(r.sales.root)}>
            Contact sales
          </Button>
        </div>
      )}

      {isAdmin(role) && <AccountDropdown />}

      <GhostMode />
    </header>
  )
}

export default LayoutHeader
