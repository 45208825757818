import {
  Braces,
  FileText,
  History,
  KeyRound,
  MessageSquare,
  Palette,
  Users,
  UserSearch
} from 'lucide-react'
import { FC, useEffect } from 'react'
import { Route } from 'react-router'
import r from 'routes'

import Settings from '@/components/settings'
import useEnforceRole from '@/hooks/useEnforceRole'
import AdminManagement from '@/pages/settings/admin-management'
import AuditLog from '@/pages/settings/audit-log'
import Billing from '@/pages/settings/billing'
import SettingsBranding from '@/pages/settings/branding'
import CustomerLookup from '@/pages/settings/customer-lookup'
import SettingsLogging from '@/pages/settings/logging'
import SMSConfiguration from '@/pages/settings/sms-configuration'
import app from '@/slices/app'
import { useAppDispatch } from '@/store'

import Developers from './developers'
import SingleSignOn from './single-sign-on'

const GeneralSettings: FC = () => {
  useEnforceRole('admin', r.root)

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(app.actions.setApp(undefined))
  }, [dispatch])

  return (
    <Settings>
      <nav>
        <a href={r.settings.branding}>
          <Palette /> Branding
        </a>
        <a href={r.settings.adminManagement}>
          <Users /> Admin Management
        </a>
        <a href={r.settings.smsConfiguration}>
          <MessageSquare /> SMS Configuration
        </a>
        <a href={r.settings.customerLookup}>
          <UserSearch /> Customer Lookup
        </a>
        <a href={r.settings.singleSignOn}>
          <KeyRound /> Single Sign-On
        </a>
        <a href={r.settings.logging}>
          <FileText /> Logging
        </a>
        {/* <a href={r.settings.billing}>
          <CreditCard /> Billing
        </a> */}
        <a href={r.settings.auditLog}>
          <History /> Audit log
        </a>
        <a href={r.settings.developers}>
          <Braces /> Developers
        </a>
      </nav>
      <main>
        <Route path={r.settings.branding} Component={SettingsBranding} />
        <Route path={r.settings.adminManagement} Component={AdminManagement} />
        <Route path={r.settings.smsConfiguration} Component={SMSConfiguration} />
        <Route path={r.settings.customerLookup} Component={CustomerLookup} />
        <Route path={r.settings.singleSignOn} Component={SingleSignOn} />
        <Route path={r.settings.logging} Component={SettingsLogging} />
        <Route path={r.settings.billing} Component={Billing} />
        <Route path={r.settings.auditLog} Component={AuditLog} />
        <Route path={r.settings.developers} Component={Developers} />
      </main>
    </Settings>
  )
}

export default GeneralSettings
