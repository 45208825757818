import asyncThunk from '@/lib/asyncThunk'
import { AdminRole } from '@/out/admin'

type Request = {
  id: string
  role: AdminRole
}

type Response = {
  id: string
  role: AdminRole
}

export const updateAdminRole = asyncThunk<Request, Response>({
  name: 'admins/role',
  method: 'PUT',
  uri: ({ id }) => `/admins/${id}/role`,
})
