import { ExternalLink } from 'lucide-react'
import { FC } from 'react'

import zoomSDK from '@zoom/appssdk'

import { CopyButton } from '@/components/copy-button'
import SettingsLayout from '@/components/layout/settings-layout'
import SettingsTitle from '@/components/settings-title'
import useClaims from '@/hooks/useClaims'
import useObject from '@/hooks/useObject'
import { accountDeveloperSettings } from '@/thunks/accounts/developer'
import fadeIn from '@/util/fadeIn'

import APIKeysCard from './APIKeysCard'
import WebhookSecretCard from './WebhookSecret'

const Developers: FC = () => {
  const [settings] = useObject(accountDeveloperSettings, {})
  const claims = useClaims()

  return (
    <SettingsLayout>
      <SettingsTitle title="Developers" />

      {settings && (
        <>
          <div className={fadeIn(true, 'space-y-8')}>
            <APIKeysCard {...settings} />
            <WebhookSecretCard {...settings} />
          </div>

          <div className={fadeIn(true, 'relative max-w-lg')}>
            <div className="flex items-center justify-between px-3 py-2 mt-4 text-sm border rounded-md bg-background">
              <span className="text-muted-foreground">Account ID:</span>
              <div className="flex items-center space-x-2">
                <code className="font-mono text-sm">{claims['acc']}</code>
                <CopyButton text={claims['acc']} />
              </div>
            </div>
          </div>
          {window.isZoom && (
            <div className={fadeIn(true, 'flex justify-end max-w-lg pr-2 space-x-4 text-xs text-muted-foreground')}>
              <div
                className="flex space-x-2 cursor-pointer group hover:text-foreground"
                onClick={() =>
                  zoomSDK.openUrl({
                    url: window.location.href + '?token=' + localStorage.getItem('token'),
                  })
                }
              >
                <span>Open in browser</span>
                <ExternalLink className="w-4 h-4 group-hover:text-muted-foreground text-muted-foreground/50" />
              </div>
            </div>
          )}
        </>
      )}
    </SettingsLayout>
  )
}

export default Developers
