import { FC, useMemo } from 'react'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Admin } from '@/out/admin'
import { updateAdminRole } from '@/thunks/admins/role'

import RoleSelect from '../Shared/RoleSelect'
import ArchiveAdminDialog from './ArchiveDialog'

type Props = {
  admins: Admin[]
  onUpdate: (admin: Partial<Admin> & { id: string }) => void
  onRemove: (id: string) => void
}

const AdminTable: FC<Props> = ({ admins, onUpdate, onRemove }) => {
  const sorted = useMemo(() => {
    return admins.sort((l, r) => {
      const lName = `${l.firstName} ${l.lastName}`
      const rName = `${r.firstName} ${r.lastName}`
      return lName.localeCompare(rName)
    })
  }, [admins])

  return (
    <Table>
      <TableHeader>
        <TableRow className="hover:bg-inherit">
          <TableHead className="w-56 pl-4">Name</TableHead>
          <TableHead className="w-80">Email</TableHead>
          <TableHead></TableHead>
          <TableHead className="w-56">Role</TableHead>
          <TableHead className="w-48"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {sorted.map(admin => (
          <TableRow key={admin.id} data-id={admin.id} className="h-14">
            <TableCell className="pl-4 whitespace-nowrap">
              {!admin.lastName ? (
                <span className="italic text-muted-foreground">unknown</span>
              ) : (
                <>
                  {admin.firstName} {admin.lastName}
                </>
              )}
            </TableCell>
            <TableCell>{admin.email}</TableCell>
            <TableCell></TableCell>
            <TableCell>
              {admin.role === 'owner' ? (
                <span className="cursor-not-allowed">Account Owner</span>
              ) : (
                <RoleSelect admin={admin} thunk={updateAdminRole} onChange={role => onUpdate({ id: admin.id, role })} />
              )}
            </TableCell>
            <TableCell className="flex items-center justify-end pr-4 space-x-4 text-right h-14">
              {admin.role !== 'owner' && <ArchiveAdminDialog admin={admin} onArchive={onRemove} />}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default AdminTable
