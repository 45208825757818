import { AlertCircle, Mail } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router'
import r from 'routes'

import GoogleIcon from '@/assets/logos/google.svg?react'
import JourneySecondary from '@/assets/logos/journey-secondary.svg?react'
import MicrosoftIcon from '@/assets/logos/microsoft.svg?react'
import LoadingButton from '@/components/loading-button'
import { Input } from '@/components/ui/input'
import { useQueryParams } from '@/hooks/useQueryParams'

import GoogleButton from './GoogleButton'
import MicrosoftButton from './MicrosoftButton'
import useSSO from './useSSO'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'

type QueryParams = {
  expired?: string
}

const errors = {
  expired: 'Your session has expired. Please sign in again.',
}

const SignIn = () => {
  const { form, authorize, timer } = useSSO()
  const params = useQueryParams<QueryParams>()
  const [error, setError] = useState<string>(() => {
    return params.expired ? errors.expired : ''
  })

  useEffect(() => {
    setError(params.expired ? errors.expired : '')
  }, [params])

  return (
    <div className="relative flex bg-secondary/50 p-4 w-full min-h-screen">
      <div className="absolute inset-0 w-full h-full overflow-hidden">
        <div
          className="-z-10 absolute inset-0"
          style={{
            background: 'radial-gradient(600px circle at center, transparent, rgba(0,42,78,0.05))',
          }}
        />
        <div
          className="absolute inset-0"
          style={{
            background: `
              linear-gradient(90deg, rgba(0,42,78,0.03) 1px, transparent 1px 35px) 50% 50% / 35px 35px,
              linear-gradient(rgba(0,42,78,0.03) 1px, transparent 1px 35px) 50% 50% / 35px 35px
            `,
            maskImage: 'radial-gradient(600px circle at center, white 30%, transparent 70%)',
            WebkitMaskImage: 'radial-gradient(600px circle at center, white 30%, transparent 70%)',
          }}
        />
      </div>
      <div className="flex flex-1 justify-center items-center">
        <div className="relative space-y-4 bg-white shadow-md p-8 border-2 border-white rounded-xl w-full max-w-sm overflow-hidden text-center">
          <div className="-top-20 left-1/2 absolute w-[200%] h-80 overflow-hidden -translate-x-1/2">
            <div className="z-10 absolute inset-0 bg-gradient-to-b from-[#002A4E]/5 to-transparent" />
            <div
              className="absolute inset-0"
              style={{
                background: `
        linear-gradient(90deg, rgba(0,42,78,0.05) 1px, transparent 1px 35px) 50% 50% / 35px 35px,
        linear-gradient(rgba(0,42,78,0.05) 1px, transparent 1px 35px) 50% 50% / 35px 35px
      `,
                maskImage: 'radial-gradient(circle 400px at center top, white 30%, transparent 70%)',
                WebkitMaskImage: 'radial-gradient(circle 400px at center top, white 30%, transparent 70%)',
              }}
            />
          </div>

          <div className="z-30 relative space-y-4">
            <JourneySecondary className="mx-auto mb-4 w-32 h-12" />
            <h1 className="font-semibold text-2xl">Sign In</h1>
            <p className="text-muted-foreground text-sm">Choose your authentication method</p>
          </div>

          {error && (
            <Alert variant="destructive" className="text-left">
              <AlertCircle className="w-4 h-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <div className="z-30 relative gap-3 grid grid-cols-2">
            <GoogleButton onError={setError}>
              <GoogleIcon className="mr-2" />
              Google
            </GoogleButton>
            <MicrosoftButton>
              <MicrosoftIcon className="mr-2" />
              Microsoft
            </MicrosoftButton>
          </div>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <span className="border-t border-border w-full" />
            </div>
            <div className="relative flex justify-center text-xs">
              <span className="bg-background px-2 text-muted-foreground">Or continue with</span>
            </div>
          </div>

          <form onSubmit={form.handleSubmit(authorize)} className="z-30 relative space-y-4">
            <div className="space-y-1 text-left">
              <label htmlFor="email" className="text-sm">
                Email
              </label>
              <div className="relative">
                <Mail className="top-1/2 left-3 absolute w-4 h-4 text-muted-foreground -translate-y-1/2" />
                <Input
                  {...form.register('email', {
                    onChange: () => form.clearErrors('email'),
                  })}
                  id="email"
                  placeholder="hi@journeyid.com"
                  className={`h-10 pl-9 ${
                    form.formState.errors.email ? 'border-destructive focus-visible:ring-destructive' : ''
                  }`}
                />
              </div>
              {form.formState.errors.email && (
                <p className="font-light text-destructive text-sm text-center">{form.formState.errors.email.message}</p>
              )}
            </div>

            <LoadingButton size="lg" timer={timer} type="submit" className="w-full">
              Sign in using SSO
            </LoadingButton>
          </form>

          <p className="z-30 relative text-muted-foreground text-xs text-center">
            Don&apos;t have an account?{' '}
            <Link to={r.authentication.signUp} className="text-primary hover:underline">
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default SignIn
