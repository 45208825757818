import { SearchIcon } from 'lucide-react'
import { FC, KeyboardEventHandler, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import r from 'routes'

import { Input } from '@/components/ui/input'
import useList from '@/hooks/useList'
import { listAccounts } from '@/watch-tower/thunks/accounts/list'

import AccountTable from './table'

const WatchTowerAccountsList: FC = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [allAccounts] = useList(listAccounts, {})

  const accounts = useMemo(
    () =>
      allAccounts?.filter(account => {
        const searchTermLower = searchTerm.toLowerCase()
        return (
          account.name.toLowerCase().includes(searchTermLower) ||
          account.id === searchTermLower ||
          account.admins?.some(
            admin =>
              admin.firstName?.toLowerCase().includes(searchTermLower) ||
              admin.lastName?.toLowerCase().includes(searchTermLower) ||
              admin.email.toLowerCase().includes(searchTermLower)
          )
        )
      }),
    [allAccounts, searchTerm]
  )

  const navigate = useNavigate()
  const handleEnter = useCallback(
    (id: string): KeyboardEventHandler<HTMLInputElement> =>
      e => {
        if (e.key !== 'Enter') return
        navigate(r.watchTower.accounts.show(id))
      },
    [navigate]
  )

  return (
    <div className="flex flex-col w-full max-w-5xl mx-auto">
      <div className="flex flex-col items-start justify-between my-4 space-y-4 md:items-center md:flex-row md:space-y-0">
        <h2 className="text-xl font-semibold">{accounts?.length ?? <>&hellip;</>} Active Accounts</h2>
        <div className="flex items-center justify-between">
          <div className="flex gap-2">
            <div className="relative">
              <SearchIcon className="absolute w-4 h-4 transform -translate-y-1/2 top-1/2 left-2 text-muted-foreground" />
              <Input
                placeholder="Search by account, name, or email"
                value={searchTerm}
                autoFocus
                onChange={e => setSearchTerm(e.target.value)}
                onKeyDown={accounts && accounts.length > 0 ? handleEnter(accounts[0].id) : undefined}
                className="pl-8 w-72 placeholder:text-xs"
              />
            </div>
            {/* <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline">
                  <ListFilterIcon className="w-4 h-4 mr-2" />
                  Filters {selectedApps.length > 0 && `(${selectedApps.length})`}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-full">
                <div className="space-y-4">
                  <MultiSelect
                    options={appOptions}
                    onValueChange={setSelectedApps}
                    placeholder="Filter by apps"
                    value={selectedApps}
                    maxCount={2}
                  />
                </div>
              </PopoverContent>
            </Popover> */}
          </div>
        </div>
      </div>
      <div className="mb-4 border rounded-md">
        <AccountTable accounts={accounts} />
      </div>
    </div>
  )
}

export default WatchTowerAccountsList
