import { FC } from 'react'
import { useParams } from 'react-router'

import LoadingButton from '@/components/loading-button'
import useGhostMode from '@/hooks/useIsGhost'
import useObject from '@/hooks/useObject'
import { retrieveAccount } from '@/watch-tower/thunks/accounts/retrieve'

import AdminsTable from './admins-table'
import AppsTable from './apps-table'
import WatchTowerAccountStatistics from './statistics'

const WatchTowerAccountsShow: FC = () => {
  const { id } = useParams<{ id: string }>()
  const [account, { patch }] = useObject(retrieveAccount, { id: id! })
  const [, { ghost, ghostTimer }] = useGhostMode(id!)

  if (!account) return null

  return (
    <div className="flex flex-col w-full max-w-5xl pb-8 mx-auto space-y-6 md:px-0">
      <div className="flex flex-col w-full px-2 pt-4 mx-auto space-y-4 md:pt-6 md:px-4 sm:flex-row sm:space-y-0 xl:px-0 max-w-7xl">
        <h1 className="flex-1 text-3xl">{account.account.name}</h1>
        <div>
          <LoadingButton timer={ghostTimer} variant="outline" onClick={() => ghost()}>
            Ghost Mode
          </LoadingButton>
        </div>
      </div>

      <WatchTowerAccountStatistics />

      <AppsTable
        accountId={id!}
        apps={account.apps}
        onUpdate={(app, status) => patch({ apps: { ...account.apps, [app]: { id: app, status } } })}
      />

      <AdminsTable admins={account.admins} />
    </div>
  )
}

export default WatchTowerAccountsShow
